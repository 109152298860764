import './Dashboard.css';
import React, { useState } from 'react';
import { Tab, Tabs } from 'react-bootstrap';
import Alerts from '../alerts/Alerts.js';
import Format from '../utils/Format.js';

export default function Dashboard() {
    const [alertSuccesses, setAlertSuccesses] = useState([]);
    const [alertInfos, setAlertInfos] = useState([]);
    const [alertWarnings, setAlertWarnings] = useState([]);
    const [alertErrors, setAlertErrors] = useState([]);

    const addInfo = info => {
        setAlertInfos(alertInfos => [...alertInfos, info]);
    }

    const addSuccess = success => {
        setAlertSuccesses(alertSuccesses => [...alertSuccesses, success]);
    };

    const addWarning = warning => {
        setAlertWarnings(alertWarnings => [...alertWarnings, warning]);
    };

    const addError = error => {
        setAlertErrors(alertErrors => [...alertErrors, error]);
    };

    const clearAlerts = () => {
        setAlertInfos([]);
        setAlertSuccesses([]);
        setAlertWarnings([]);
        setAlertErrors([]);
    }

    return (
        <div className='dashboard'>
            <Alerts 
                successes={alertSuccesses}
                infos={alertInfos}
                warnings={alertWarnings}
                errors={alertErrors}
            />
            <Tabs defaultActiveKey="pretty-print" className="mb-3">
                <Tab eventKey="pretty-print" title="Format">
                    <Format
                        addWarning={addWarning}
                        addError={addError}
                        clearAlerts={clearAlerts}
                    />
                </Tab>
           </Tabs>
        </div>
    );
}