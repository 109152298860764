import { Container, ListGroup, Navbar } from 'react-bootstrap';
import React from 'react';

export default function Header() {
    return (
        <Navbar bg='dark' data-bs-theme='dark' sticky='top'>
            <Container fluid>
                <Navbar.Brand>sql.ljunggren.io</Navbar.Brand>
                <Navbar.Toggle />
                 <Navbar.Collapse className='justify-content-end'>
                    <Navbar.Text>
                        <ListGroup horizontal defaultActiveKey='https://sql.ljunggren.io'>
                            <ListGroup.Item action href='https://json.ljunggren.io'>JSON</ListGroup.Item>
                            <ListGroup.Item action href='https://xml.ljunggren.io'>XML</ListGroup.Item>
                            <ListGroup.Item action href='https://sql.ljunggren.io'>SQL</ListGroup.Item>
                        </ListGroup>
                    </Navbar.Text>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}